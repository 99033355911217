@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

img {
  display: block;
  width: 100%;
}

.container {
  padding: 0 2rem;
}

.logosize {
  max-width: 8rem;
  max-height: 8rem;
}

.heroimg {
  max-width: 15rem;
}

.serif {
  font-family: "Libre Baskerville", serif;
}

.heroimg1 {
  height: 30rem;
  width: 20rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.heroimg2 {
  height: 30rem;
  width: 18rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.heroimg3 {
  height: 30rem;
  width: 16rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tick {
  height: 32px;
  width: 32px;
}

.socialLink,
.socialLink {
  height: 60px;
  width: 60px;
}

.dogIcon,
.dogIcon2 {
  height: 200px;
  width: 200px;
  opacity: 0.7;
}
.dogIconDiv {
  bottom: 20%;
  right: 15%;
}

.contactIcon {
  width: 24px;
  height: 24px;
}
.contactIconPhone {
  transform: rotateY(180deg) rotate(15deg);
  width: 20px;
  height: 20px;
}
.homelessHero {
  height: 75vh;
  width: 100%;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.homelessPaws {
  position: absolute;
  top: 28%;
  right: 10%;
  transform: rotate(-40deg);
  opacity: 0.55;
}
.sterPaws {
  position: absolute;
  bottom: 0;
  right: 7%;
  transform: rotate(40deg);
  opacity: 0.55;
  z-index: 999;
}
.chipPaws {
  position: absolute;
  bottom: -8%;
  right: 25%;
  z-index: 999;
  transform: rotate(25deg);
  opacity: 0.55;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.psySwiper {
  height: 50rem;
  width: 50rem;
}

.adoptedSwiper {
  height: 95vh;
  width: 70vw;
}

.dogsGridItem {
  height: 15rem;
  width: 15rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.modalImg {
  height: 90vh;
  width: 60vw;
}

.bigIcon {
  height: 350px;
  width: 350px;
}
.midIcon {
  height: 225px;
  width: 225px;
}
.ctaIcon {
  height: 125px;
  width: 125px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warningBarIcon {
  position: absolute;
  top: 30%;
  right: 20%;
  transform: rotate(20deg);
  z-index: -1;
}

.warningBarIcon2 {
  position: absolute;
  top: 2%;
  right: 5%;
  transform: rotate(20deg);
  z-index: -1;
}

.newsElementIcons {
  height: 34px;
  width: 34px;
}

.petsMainSwiper {
  min-height: 75vh;
}

.contactDog {
  width: 175px;
  height: 175px;
}

.loginIcon {
  height: 24px;
  width: 24px;
}

.signInScreen {
  height: calc(100vh - 14.5rem);
}

.manageScreen {
  height: calc(100vh - 32rem);
}

.cardIcon {
  height: 28px;
  width: 28px;
}

.menuHeight {
  height: 28rem;
}

.reportDiv {
  max-height: 70rem;
  max-width: 50rem;
}

.banner {
  width: 15rem;
  bottom: 70%;
  right: 0;
}

@media only screen and (max-width: 96em) {
  .heroimg1 {
    width: 16rem;
  }
  .heroimg2 {
    width: 14rem;
  }
  .heroimg3 {
    width: 12rem;
  }
  .psySwiper {
    height: 40rem;
    width: 40rem;
  }
}
@media only screen and (max-width: 80em) {
  .heroimg1 {
    width: 21rem;
  }
  .heroimg2 {
    width: 19rem;
  }
  .heroimg3 {
    width: 17rem;
  }
  .bigIcon {
    height: 200px;
    width: 200px;
  }
  .midIcon {
    height: 175px;
    width: 175px;
  }
  .ctaIcon {
    left: 65%;
    top: 40%;
  }
  .psySwiper {
    height: 50rem;
    width: 50rem;
  }
  .dogsGridItem {
    height: 16rem;
    width: 16rem;
  }
  .homelessPaws {
    top: 30%;
    right: 10%;
  }
}
@media only screen and (max-width: 64em) {
  .heroimg1 {
    width: 16rem;
  }
  .heroimg2 {
    width: 14rem;
  }
  .heroimg3 {
    width: 12rem;
  }
  .socialLink,
  .socialLink {
    height: 40px;
    width: 40px;
  }
  .warningBarIcon {
    top: 30%;
    right: 5%;
  }
  .dogIcon {
    height: 150px;
    width: 150px;
  }
  .dogIconDiv {
    bottom: 45%;
    right: 10%;
  }
  .newsElementIcons {
    height: 28px;
    width: 28px;
  }
  .dogsGridItem {
    height: 14rem;
    width: 14rem;
  }
  .psySwiper {
    height: 42rem;
    width: 42rem;
  }
  .adoptedSwiper {
    height: 90vh;
    width: 90vw;
  }
  .bigIcon {
    height: 125px;
    width: 125px;
  }
  .modalImg {
    height: 90vh;
    width: 90vw;
  }
  .petsMainSwiper {
    min-height: 65vh;
  }
  .contactDog {
    width: 125px;
    height: 125px;
  }
  .warningBarIcon2 {
    right: 0;
  }
  .banner {
    width: 10rem;
    bottom: 0;
    top: 2%;
    right: 2%;
  }
}

@media only screen and (max-width: 48em) {
  .heroimg1 {
    height: 20rem;
    width: 14rem;
  }
  .heroimg2 {
    height: 20rem;
    width: 12rem;
  }
  .heroimg3 {
    height: 20rem;
    width: 10rem;
  }
  .dogsGridItem {
    height: 12rem;
    width: 12rem;
  }
  .psySwiper {
    height: 38rem;
    width: 38rem;
  }
  .petsMainSwiper {
    min-height: 55vh;
  }
  .warningBarIcon2 {
    top: 5%;
  }
  .banner {
    width: 8rem;
  }
}

@media only screen and (max-width: 40em) {
  .heroimg1 {
    height: 20rem;
    width: 12rem;
  }
  .heroimg2 {
    height: 20rem;
    width: 10rem;
  }
  .heroimg3 {
    height: 20rem;
    width: 8rem;
  }
  .newsElementIcons {
    height: 20px;
    width: 20px;
  }
  .dogsGridItem {
    height: 10rem;
    width: 10rem;
  }
  .psySwiper {
    height: 31rem;
    width: 31rem;
  }
  .adoptedSwiper {
    height: 75vh;
    width: 90vw;
  }
  .modalImg {
    height: 60vh;
    width: 90vw;
  }
  .menuHeight {
    height: 24rem;
  }
}
@media only screen and (max-width: 33em) {
  .heroimg1 {
    height: 16rem;
    width: 10rem;
  }
  .heroimg2 {
    height: 16rem;
    width: 8rem;
  }
  .heroimg3 {
    height: 16rem;
    width: 6rem;
  }
  html {
    font-size: 90%;
  }
  .homelessHero {
    height: 75vh;
    background-position: bottom;
    background-position-x: 60%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .psySwiper {
    height: 29rem;
    width: 29rem;
  }
  .dogsGridItem {
    height: 8.5rem;
    width: 8.5rem;
  }
  .adoptedSwiper {
    height: 65vh;
    width: 90vw;
  }
  .contactDog {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (max-width: 26em) {
  html {
    font-size: 80%;
  }
  .warningBarIcon {
    top: 30%;
    right: 7%;
  }
  .psySwiper {
    height: 28rem;
    width: 28rem;
  }
  .dogsGridItem {
    height: 8.5rem;
    width: 8.5rem;
  }
  .adoptedSwiper {
    height: 50vh;
    width: 90vw;
  }
  .contactDog {
    width: 75px;
    height: 75px;
  }
}
@media only screen and (max-width: 23.75em) {
  html {
    font-size: 75%;
  }
}
